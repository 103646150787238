import { useModalFilterText, useModalText } from 'components/ModalText/ModalText.context';
import { useMemo } from 'react';
import { useRootStore } from 'stores/RootStore';

export const useUserGetTariffInfo = () => {
  const { userStore, tariffsStore } = useRootStore();
  const info = useMemo(() => {
    if (!userStore.authorized) {
      return {
        activeUserTariff: null,
        authorized: false,
        tariffInfo: null,
      };
    }
    const tariff = tariffsStore.tariffs.items.find((item) => item.id === userStore.activeUserTariff?.tariff_id) || null;
    return {
      activeUserTariff: userStore.activeUserTariff,
      authorized: userStore.authorized,
      tariffInfo: tariff,
    };
  }, [
    userStore.authorized,
    userStore.activeUserTariff,
    tariffsStore.loadingStage.isLoading,
    tariffsStore.tariffs.items,
  ]);

  return info;
};

export const usePermission = () => {
  const { toggleOpen } = useModalFilterText();
  const { changeMsg: changeMsgNotAuth, toggleOpen: toggleOpenNotAuth } = useModalText();
  const { userStore } = useRootStore();
  const tariff = useUserGetTariffInfo();

  const onOpenNotAuthPopup = () => {
    changeMsgNotAuth('Для доступа ко всем возможностям зарегистрируйтесь или войдите в личный кабинет');
    toggleOpenNotAuth(true);
  };

  const isPermissionBlocked = useMemo(() => {
    if (!userStore.authorized) {
      return true;
    } else if (userStore.activeUserTariff) {
      if (
        tariff.tariffInfo === null ||
        tariff?.tariffInfo?.robot_assistant === false ||
        tariff?.tariffInfo?.smart_search === false
      ) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }, [userStore.authorized, userStore.activeUserTariff, tariff]);

  const onShowPopup = isPermissionBlocked
    ? () => {
        if (isPermissionBlocked) {
          if (userStore.authorized) {
            return toggleOpen(true);
          } else {
            return onOpenNotAuthPopup();
          }
        }

        return undefined;
      }
    : undefined;

  return {
    onShowPermissionPopup: onShowPopup,
    isPermissionBlocked,
    userTariff: tariff,
  };
};
