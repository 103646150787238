import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import prize from 'assets/prize.png';
import s from './RegistrationSuccessStep.module.scss';
import { Button } from 'components/Button';
import { ModalPaymentTrial } from 'pages/TariffsPage/modals/ModalPaymentTrial/ModalPaymentTrial';
import { TariffModel } from 'models/TariffModel';
import { useRootStore } from 'stores/RootStore';

export const RegistrationSuccessStep = observer(() => {
  const { tariffsStore, authStore } = useRootStore();
  const { popupController } = authStore;
  const [data, setData] = useState<TariffModel | null>(null);
  const onClick = () => {
    const trialTariff = tariffsStore.tariffs.items.find((item) => item.name === 'Максимум');
    if (trialTariff) {
      setData(trialTariff);
      popupController.close();
    }
  };
  return (
    <div className={s.content}>
      <img className={s.content__img} src={prize} alt="" />
      <h4 className={s.content__title}>Максимум за 1 рубль</h4>
      <p className={s.content__text}>Подключите пробный период на 3 дня</p>
      <Button onClick={onClick}>Подключить</Button>
      <ModalPaymentTrial
        onClose={() => {
          setData(null);
        }}
        data={data}
      />
    </div>
  );
});
