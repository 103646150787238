import React from 'react';
import s from './PriceItem.module.scss';

export const PriceItem = ({
  color = 'dark',
  name,
  price,
  discount,
  className = '',
  round,
}: {
  name: string;
  price: string;
  discount?: number;
  color?: 'white' | 'dark';
  className?: string;
  round?: 'top' | 'bottom';
}) => {
  return (
    <div className={s.card + ' ' + s[color] + ' ' + className + ' ' + s[round ? round : 'round_null']}>
      <div className={s.name}>{name}</div>
      <div className={s.priceWrap}>
        <div className={s.price}>{price} ₽</div>
        {discount ? <div className={s.discount}>-{discount}%</div> : <div />}
      </div>
    </div>
  );
};
