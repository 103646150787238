import { Input, InputRef } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useLotListStore } from 'stores/LotListCatalogStore/context';

import s from './SearchBar.module.scss';
import { SearchOutlined } from '@ant-design/icons';
import { useWidth } from 'hooks/useWidth';
import { PriceSelect } from '../SideFilters/Filters/PriceSelect';
import { Geography } from '../SideFilters/Filters/Geography';

type SearchBarProps = {
  className?: string;
};

const SearchBar = ({ className }: SearchBarProps): React.ReactElement<SearchBarProps> => {
  const { filters } = useLotListStore();
  const { isDesktop } = useWidth();
  const refInput = React.useRef<InputRef | null>(null);

  const handleSearchInput = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      filters.search.change(e.target.value);

      if (e.target.value === '') {
        filters.setSearchValue(e.target.value);
      }
    },
    [filters.search.change],
  );

  const onSearch = () => {
    filters.setSearchValue(filters.search.value);
    filters.setSideFilters();
  };

  React.useEffect(() => {
    refInput.current?.input?.classList.add('ym-record-keys');
  }, []);

  return (
    <div className={s.wrap}>
      <Input
        ref={refInput}
        className={cn(s['search-bar'], className)}
        value={filters.search.value}
        onChange={handleSearchInput}
        placeholder="Тип объектов, кадастровый номер"
        size="large"
        prefix={<SearchOutlined className={s.icon} />}
        allowClear
      />
      {isDesktop && (
        <>
          <PriceSelect />
          <Geography placeholderSelect="Регион" className={s.select} dropdownStyle={{ width: 'min-content' }} />
        </>
      )}
      <button onClick={onSearch} className={s.btn}>
        Найти
      </button>
    </div>
  );
};

export default observer(SearchBar);
