import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { PageContainer } from 'components/PageContainer';
import { ServicesStoreProvider } from 'stores/ServicesStore/context';
import { Button } from 'components/Button';
import { useNavigate } from 'react-router-dom';
import { RouteFullPath } from 'config/routes';
import { FormServicesOrder } from 'components/FormServicesOrder/FormServicesOrder';
import { CardInteresting } from '../../components/CardInteresting/CardInteresting';
import bannerMpbileImg from 'assets/audit-legal/banner-audit-mobile.png';
import bannerImg from 'assets/audit-legal/banner-audit.jpg';
import smallBannerAudit from 'assets/audit-legal/small-banner-audit.png';
import smallBannerAuditMob from 'assets/audit-legal/small-banner-audit-mobile.png';
import onlineImg from 'assets/audit-legal/online.png';
import escortImg from 'assets/audit-legal/escort.png';
import s from './AuditLegalPage.module.scss';
import { Faq } from '../../components/Faq/Faq';
import { Reports } from './components/Reports/Reports';
import { Advanced } from './components/Advantage/Advantage';
import { BannerFull } from 'pages/ServicesInfoPage/components/BannerFull/BannerFull';
import { PriceSection } from './components/PriceSection/PriceSection';
import { ymEvents } from 'utils/ym';

const ServisesInfoAuditLegalPage = () => {
  const navigate = useNavigate();
  return (
    <ServicesStoreProvider>
      <BannerFull
        image={{ 540: bannerImg, 240: bannerMpbileImg }}
        title={'Юридический аудит объектов '}
        description={'Минимизируйте риски, доверив проверку документов профессионалам'}
      />

      <PageContainer className={cn(s['lot-page'], s['lot-page-mobile'])}>
        <h2 className={s.title + ' ' + s.titleSection + ' ' + s.titleSectionFirst}>Зачем нужен юридический аудит?</h2>
        <div className={s.smallBanner}>
          <div className={s.smallBannerContent}>
            <h3 className={s.title + ' ' + s.smallBannerContentTitle}>Безопасность</h3>
            <p className={s.text + ' ' + s.smallBannerDesc}>
              Своевременное выявление потенциальных рисков, обеспечивает безопасность и защищает <br />
              интересы участника торговой процедуры
            </p>
          </div>
          <picture>
            <source srcSet={smallBannerAudit} media="(min-width: 767.5px)" />
            <source srcSet={smallBannerAuditMob} media="(min-width: 240px)" />
            <img className={s.smallBannerAuditImg} src={smallBannerAudit} alt="" />
          </picture>
        </div>
      </PageContainer>

      <PageContainer className={cn(s['lot-page'], s['lot-page-mobile'])}>
        <Advanced />
        <h2 className={s.title + ' ' + s.titleSection + ' ' + s.titleReport}>Что покажет отчет</h2>
        <Reports />
        <section>
          <h2 className={s.title + ' ' + s.titlePrice}>
            Стоимость услуги <br />в зависимости <br />
            от тарифа
          </h2>
          <PriceSection />
          <Button
            className={s.priceBtn}
            onClick={() => {
              navigate(RouteFullPath.tariffs);
            }}
          >
            Подробнее о тарифах
          </Button>
        </section>
        <section className={s.formSection} id="form">
          <FormServicesOrder
            onSuccess={() => {
              ymEvents.ostavitZayavkuYur();
            }}
          />
        </section>
        <section className={s.servicesMoreSection}>
          <h2 className={s.title + ' ' + s.titleMore}>Может быть интересно</h2>
          <div className={s.rowMore}>
            <CardInteresting
              title={'Сопровождение сделки'}
              description={'Комплексная услуга, которая позволяет обеспечить безопасность и юридическую чистоту сделки'}
              image={escortImg}
              onClick={() => navigate('/' + RouteFullPath.services_escort)}
            />
            <CardInteresting
              title={'Онлайн-показ'}
              description={'Смотрите объекты в реальном времени, не тратя время на дорогу'}
              image={onlineImg}
              onClick={() => navigate('/' + RouteFullPath.services_online)}
            />
          </div>
        </section>
        <section className={s.sectionFaq}>
          <h2 className={s.title + ' ' + s.titleFaq}>Частые вопросы</h2>
          <Faq />
        </section>
      </PageContainer>
    </ServicesStoreProvider>
  );
};

export default observer(ServisesInfoAuditLegalPage);
