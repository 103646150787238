import { BidType } from 'entities/bid';
import { TimelineServer } from 'entities/timeline';

export type LotImage = {
  type: string;
  url?: string;
  key?: string;
};

export type LotDoc = {
  name: string;
  url: string;
};

export enum LotStatus {
  published = 'published',
  acceptingNotStarted = 'accepting_not_started',
  acceptingApplications = 'accepting_applications',
  sumUp = 'sum_up',
  determiningWinner = 'determining_winner',
  succeed = 'succeed',
  failed = 'failed',
  canceled = 'cancelled',
  archive = 'archive',
}

export enum LotSource {
  gisTorgi = 'gis_torgi',
  investMoscow = 'invest_moscow',
  sberAst = 'sber_ast',
  tektorg = 'tektorg',
  lotOnline = 'lot_online',
  roseltorg = 'roseltorg',
  rtsTender = 'rts_tender',
  zakazrf = 'zakazrf',
  fabrikant = 'fabrikant',
  etpgpb = 'etpgpb',
  eltorg = 'eltorg',
  empty = 'empty',
  alfalot = 'alfalot',
}

export type AuctionOrganizer = { name: string; inn: number; kpp: number; code: number; ogrn: number };

export type LotBids = Record<BidType, boolean>;
export type LotBaseServer = {
  id: number;
  title: string;
  object_type: string;
  // город или регион
  city: string;
  image_count: number;
  external_id: string;
  price_min: number | null;
  price_step: number | null;
  total_area: number | null;
  floor: string | null;
  amount_of_floors: string | null;
  entrance: number | null;
  address: string;
  description: string | null;
  status: LotStatus;
  source: LotSource;
  auction_type: string;
  auction_format: string;
  images: LotImage[];
  auction_organizer: AuctionOrganizer | null;
  timeline: TimelineServer;
  views_count: number;
  created_at: string;
  cutoff_price: number | null;
  // Отметка аукциона на понижение
  is_downgrade_auction: boolean;
  documents: LotDoc[];
};

export type LotPublicServer = {
  image_count: number;
} & LotBaseServer;

export type LotServer = {
  id: number;
  title: string;
  object_type: string;
  // город или регион
  city: string;
  external_id: string;
  price_min: number | null;
  price_step: number | null;
  total_area: number | null;
  floor: string | null;
  amount_of_floors: string | null;
  entrance: number | null;
  lot_url: string;
  address: string;
  description: string | null;
  status: LotStatus;
  source: LotSource;
  auction_type: string;
  auction_format: string;
  images: LotImage[];
  documents: LotDoc[];
  auction_organizer: AuctionOrganizer | null;
  timeline: TimelineServer;
  views_count: number;
  created_at: string;
  is_favorite: boolean;
  is_subscribed: boolean;
  is_bid_existed: LotBids | null;
  // Цена отсечения
  cutoff_price: number | null;
  // Шаг понижения цены
  price_decrease_step: number | null;
  // Отметка аукциона на понижение
  is_downgrade_auction: boolean;
} & LotBaseServer;

export type LotsResponse<T = LotServer> = {
  items: T[];
  // Количество лотов в списке с учетом текущих фильтров запроса
  total: number;
};

export type ProfileLotsResponse<T> = {
  items: T[];
  // Количество лотов в списке с учетом текущих фильтров запроса
  total: number;
  // Общее количество лотов пользователя без учета текущих фильтров запроса
  total_all: number;
};
