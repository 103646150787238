import React, { ReactNode } from 'react';
import s from './TariffsCard.module.scss';
import { Button } from 'components/Button';
import { observer } from 'mobx-react-lite';
import CheckIcon from 'components/icons/CheckIcon';
import SubIcon from 'components/icons/SubIcon';

type ListTypeItem = ReactNode | boolean | string;

export const TariffsCard = observer(
  ({
    name,
    price,
    list,
    active,
    onActiveSubscribe,
    info,
    id,
    auth = false,
    authText = 'Подключить',
    onMouseLeave,
    onMouseMove,
  }: {
    id: string | number;
    onActiveSubscribe?: (id: string | number) => void;
    list?: ListTypeItem[];
    active?: boolean;
    price: string;
    name: string;
    info?: { text: string; image: string };
    auth?: boolean;
    authText?: string;
    onMouseLeave?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onMouseMove?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  }) => {
    const onClick = () => (onActiveSubscribe ? onActiveSubscribe(id) : undefined);
    return (
      <div className={s.card + ' ' + (active ? s.activeCard : '')}>
        {info && (
          <div className={s.info} data-img={info.image} style={{ backgroundImage: `url(${info.image})` }}>
            <div className={s.info__header}>
              <p className={s.info__name}>{name}</p>

              {active ? (
                <div className={s.activeInfo}>Ваш текущий тариф</div>
              ) : (
                <Button className={s.info__btn} onClick={onClick}>
                  {!auth ? authText : 'Подключить'}
                </Button>
              )}
            </div>
            <p className={s.info__text}>{info.text}</p>
          </div>
        )}
        <div className={s.header}>
          <p className={s.name}>{name}</p>
          <p className={s.price}>{price}</p>
          {active ? (
            <div className={s.active}>Ваш текущий тариф</div>
          ) : (
            <Button onClick={onClick}>{!auth ? authText : 'Подключить'}</Button>
          )}
        </div>
        {list?.map((item, index) => (
          <div
            key={index}
            className={s.card__item}
            data-card-item={index}
            onMouseLeave={onMouseLeave}
            onMouseMove={onMouseMove}
          >
            {typeof item === 'boolean' ? item ? <CheckIcon /> : <SubIcon /> : item}
          </div>
        ))}
      </div>
    );
  },
);
