import etpgpb from 'icons/etpgpb.png';
import fabrikant from 'icons/fabrikant.png';
import gisTorgi from 'icons/gisTorgi.png';
import investMoscow from 'icons/investMoscow.png';
import lotOnline from 'icons/lotOnline.png';
import roseltorg from 'icons/roseltorg.png';
import rtsTender from 'icons/rtsTender.png';
import sberA from 'icons/sberA.png';
import tekTorg from 'icons/tekTorg.png';
import zakazrf from 'icons/zakazrf.png';
import eltorg from 'icons/eltorg.png';
import empty from 'icons/favicon.png';
import alfa from 'icons/alfa.png';
import { LotSource, LotStatus } from './server';

export const lotSourceConfig: Record<LotSource, { icon: string; title: string }> = {
  [LotSource.gisTorgi]: {
    icon: gisTorgi,
    title: 'ГИС\xA0Торги',
  },
  [LotSource.investMoscow]: {
    icon: investMoscow,
    title: 'Инвестиционный портал г.\xA0Москвы',
  },
  [LotSource.sberAst]: {
    icon: sberA,
    title: 'Сбер-АСТ',
  },
  [LotSource.tektorg]: {
    icon: tekTorg,
    title: 'ТЭК-Торг',
  },
  [LotSource.lotOnline]: {
    icon: lotOnline,
    title: 'ЭТП РАД',
  },
  [LotSource.roseltorg]: {
    icon: roseltorg,
    title: 'Росэлторг',
  },
  [LotSource.rtsTender]: {
    icon: rtsTender,
    title: 'РТС-тендер',
  },
  [LotSource.zakazrf]: {
    icon: zakazrf,
    title: 'ЗаказРФ',
  },
  [LotSource.fabrikant]: {
    icon: fabrikant,
    title: 'Фабрикант',
  },
  [LotSource.etpgpb]: {
    icon: etpgpb,
    title: 'ЭТП ГПБ',
  },
  [LotSource.eltorg]: {
    icon: eltorg,
    title: 'ELTorg',
  },
  [LotSource.empty]: {
    icon: empty,
    title: 'empty',
  },
  [LotSource.alfalot]: {
    icon: alfa,
    title: 'Альфалот',
  },
};

export type Status = {
  statusTitle: string;
  statusTagColor: string;
};

export const DEFAULT_STATUS_CONFIG: Status = {
  statusTitle: 'Опубликован',
  statusTagColor: 'green',
};

export const lotStatusConfig: Record<LotStatus, Status> = {
  [LotStatus.published]: {
    statusTitle: 'Опубликован',
    statusTagColor: 'green',
  },
  [LotStatus.acceptingApplications]: {
    statusTitle: 'Прием заявок',
    statusTagColor: 'geekblue',
  },
  [LotStatus.determiningWinner]: {
    statusTitle: 'Определение победителя',
    statusTagColor: 'geekblue',
  },
  [LotStatus.succeed]: {
    statusTitle: 'Процедура состоялась',
    statusTagColor: 'green',
  },
  [LotStatus.failed]: {
    statusTitle: 'Процедура не состоялась',
    statusTagColor: 'default',
  },
  [LotStatus.canceled]: {
    statusTitle: 'Торги отменены',
    statusTagColor: 'red',
  },
  [LotStatus.acceptingNotStarted]: {
    statusTitle: 'Прием заявок не начался',
    statusTagColor: 'default',
  },
  [LotStatus.sumUp]: {
    statusTitle: 'Подведение итогов',
    statusTagColor: 'geekblue',
  },
  [LotStatus.archive]: {
    statusTitle: 'Архив',
    statusTagColor: 'default',
  },
};
