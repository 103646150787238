import React from 'react';
import { PriceItem } from '../PriceItem/PriceItem';
import s from './PriceSection.module.scss';
import { observer } from 'mobx-react-lite';
import { useServicesStore } from 'stores/ServicesStore/context';
import { useWidth } from 'hooks/useWidth';
import { formatNumberToString } from 'utils/formatNumberToString';

export const PriceSection = observer(() => {
  const { auditLigalList } = useServicesStore();
  const { width = window.innerWidth } = useWidth();
  return (
    <div className={s.rowsPrice}>
      {auditLigalList.items.map((item, index) => (
        <PriceItem
          key={item.id}
          name={width <= 480 ? 'на ' + item.tariff_name : 'на тарифе ' + item.tariff_name}
          price={formatNumberToString(parseInt(item.cost), { minimumFractionDigits: 0 })}
          round={index === 0 ? 'top' : index === auditLigalList.items.length - 1 ? 'bottom' : undefined}
          color={index % 2 === 0 ? 'dark' : 'white'}
          discount={item.discount === 0 ? undefined : item.discount}
        />
      ))}
    </div>
  );
});
